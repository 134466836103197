import { AddressPayload, Cart, Order } from '@medusajs/medusa';
import { isEmpty } from 'lodash';
import React, { createContext, useEffect, useState } from 'react';

import notification, {
  closeNotification,
} from '../components/utility/notification';
import { useCustomer } from '../hooks/use-customer';
import { useMedusa } from '../hooks/use-medusa';
import { useRegion } from '../hooks/use-region';

const defaultCartContext = {
  cart: {
    items: [],
  } as unknown as Cart,
  loading: false,
  actions: {
    updateCart: () => {},
    resetCart: () => {},
    addItem: async (item) => {},
    removeItem: async (id) => {},
    updateQuantity: async (item) => {},
    addDiscount: async () => {},
    removeDiscount: async () => {},
    createPaymentSession: async () => {},
    setPaymentSession: async (providerId) => {},
    completeCart: async () => ({}),
    getCartShippingOptions: async () => {},
    addShippingMethod: async () => {},
    addShippingAddress: async (address) => {},
    setVisible: (value: boolean) => {},
  },
};

const CartContext = createContext<{
  cart: Cart;
  loading: boolean;
  actions: {
    setVisible: (value: boolean) => void;
    completeCart: (
      cartId: string | null,
      shippingAddress: AddressPayload,
      cartMetadata: Record<string, unknown>
    ) => Promise<Order>;
  };
}>(defaultCartContext);
export default CartContext;

const CART_ID = 'cart_id';
const isBrowser = typeof window !== 'undefined';

export const CartProvider = (props) => {
  const [cart, setCart] = useState(defaultCartContext.cart);
  const [loading, setLoading] = useState(defaultCartContext.loading);
  const [visible, setVisible] = useState(false);
  const client = useMedusa();
  const { region } = useRegion();
  const { customer } = useCustomer();

  const setCartItem = (cart) => {
    if (isBrowser) {
      localStorage.setItem(CART_ID, cart.id);
    }

    setCart(cart);
  };

  // sort cart items as Medusa doesn't sort items by its created date by default
  const sortCartItems = (cart) => {
    cart.items.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    return cart;
  };

  useEffect(() => {
    const initializeCart = async () => {
      const existingCartId = isBrowser ? localStorage.getItem(CART_ID) : null;

      if (existingCartId && existingCartId !== null) {
        try {
          const existingCart = await client.carts
            .retrieve(existingCartId)
            .then(({ cart }) => sortCartItems(cart));
          if (!existingCart.completed_at) {
            setCartItem(existingCart);
            return;
          }
        } catch (e) {
          localStorage.setItem(CART_ID, null);
        }
      }

      const newCart = await client.carts.create({}).then(({ cart }) => cart);
      setCartItem(newCart);
      setLoading(false);
    };

    initializeCart();
  }, [client.carts]);

  useEffect(() => {
    const updateCartRegion = async () => {
      setLoading(true);

      const cartId = cart.id;

      if (cart.region) {
        const isEqual = cart.region.id === region.id;
        if (isEqual) {
          setLoading(false);
          return;
        }
      }

      const cartRes = await client.carts
        .update(cartId, { region_id: region.id })
        .then(({ cart }) => cart);

      if (cartRes) {
        setCart(cartRes);
      }

      setLoading(false);
    };

    if (cart.id) {
      updateCartRegion();
    }
  }, [cart.id, cart.region, region?.id, client.carts]);

  const addItem = async (item) => {
    try {
      setLoading(true);
      notification({
        key: 'add-to-cart',
        type: 'loading',
        message: 'กำลังเพิ่มสินค้า...',
      });
      let cartId = cart.id;

      if (!cartId) {
        const newCart = await client.carts.create().then(({ cart }) => cart);
        cartId = newCart.id;
        setCartItem(newCart);
      }

      await client.carts.lineItems
        .create(cartId, item)
        .then(({ cart }) => setCart(sortCartItems(cart)))
        .catch((r) => {
          throw r.response.data;
        });
    } catch (e) {
      notification({
        key: 'add-to-cart-error',
        type: 'error',
        message:
          e.type == 'not_found'
            ? `ขออภัย ไม่พบสินค้านี้อยู่ในระบบแล้ว กรุณาติดต่อผู้ดูแลระบบหากมีข้อสงสัยเพิ่มเติม`
            : `ขออภัย พบข้อขัดข้องในการเพิ่มสินค้าลงตะกร้าของคุณ กรุณาลองใหม่อีกครั้ง`,
      });
      throw e;
    } finally {
      setLoading(false);
      closeNotification('add-to-cart');
    }
  };

  const removeItem = async (id) => {
    setLoading(true);

    const cartId = cart.id;

    return client.carts.lineItems.delete(cartId, id).then(({ cart }) => {
      setCart(sortCartItems(cart));
      setLoading(false);
    });
  };

  const updateQuantity = async (item) => {
    setLoading(true);

    const cartId = cart.id;

    return client.carts.lineItems
      .update(cartId, item.id, { quantity: item.quantity })
      .then(({ cart }) => {
        setCart(sortCartItems(cart));
        setLoading(false);
      });
  };

  const addDiscount = async (discount) => {
    setLoading(true);

    const cartId = cart.id;

    return client.carts
      .update(cartId, { discounts: [{ code: discount }] })
      .then(({ cart }) => {
        setCart(sortCartItems(cart));
        setLoading(false);
      });
  };

  const removeDiscount = async () => {
    setLoading(true);

    const cartId = cart.id;

    return client.carts.update(cartId, { discounts: [] }).then(({ cart }) => {
      setCart(sortCartItems(cart));
      setLoading(false);
    });
  };

  const getCartShippingOptions = async (providedCartId = null) => {
    setLoading(true);

    const cartId = providedCartId || cart.id;

    return client.shippingOptions
      .listCartOptions(cartId)
      .then(({ shipping_options }) => {
        setLoading(false);
        return shipping_options;
      });
  };

  const addShippingMethod = async (payload) => {
    setLoading(true);

    const cartId = cart.id;

    return client.carts.addShippingMethod(cartId, payload).then(({ cart }) => {
      setCart(sortCartItems(cart));
      setLoading(false);
    });
  };

  const updateCart = async (payload) => {
    setLoading(true);

    const cartId = cart.id;

    return client.carts.update(cartId, payload).then(({ cart }) => {
      setCart(sortCartItems(cart));
      setLoading(false);
    });
  };

  const createPaymentSession = async (providedCartId = null) => {
    setLoading(true);

    const cartId = providedCartId ?? cart.id;

    return client.carts
      .createPaymentSessions(cartId)
      .then(({ cart, response }) => {
        setCart(sortCartItems(cart));
        setLoading(false);
        return { cart, response };
      });
  };

  const setPaymentSession = async (providerId, providedCartId = null) => {
    setLoading(true);

    const cartId = providedCartId ?? cart.id;

    return client.carts
      .setPaymentSession(cartId, { provider_id: providerId })
      .then(({ cart }) => {
        setCart(sortCartItems(cart));
        setLoading(false);
        return cart;
      });
  };

  const addShippingAddress = async (address: AddressPayload) => {
    const cartId = cart.id;
    await client.carts
      .update(cartId, {
        shipping_address: address,
      })
      .then(({ cart }) => {
        setCart(sortCartItems(cart));
        setLoading(false);
      });
  };

  const completeCart = async (
    providedCartId = null,
    shippingAddress: AddressPayload,
    cartMetadata: Record<string, unknown> = {}
  ) => {
    if (!customer || !shippingAddress) {
      throw new Error(
        'Invalid parameters. customer and shippingAddress are required'
      );
    }

    setLoading(true);

    const cartId = providedCartId ?? cart.id;

    let customerPayload = {};
    // add customer to cart
    if (!cart.customer_id) {
      customerPayload = {
        customer_id: customer.id,
        email: customer.email,
      };
    }

    await client.carts.update(cart.id, {
      ...customerPayload,
      metadata: cartMetadata,
    });

    await addShippingAddress(shippingAddress);

    // add shipping option
    if (isEmpty(cart.shipping_methods)) {
      const options = await client.shippingOptions
        .listCartOptions(cart.id)
        .then(({ shipping_options }) => shipping_options)
        .catch((_) => []);
      await client.carts.addShippingMethod(cartId, {
        option_id: options[0].id,
      });
    }

    // create payment session
    if (isEmpty(cart.payment_sessions) && cart.total > 0) {
      await createPaymentSession();
    }

    const { data: order } = await client.carts.complete(cartId);

    setCart(defaultCartContext.cart);
    setLoading(false);
    return order;
  };

  return (
    <CartContext.Provider
      {...props}
      value={{
        ...defaultCartContext,
        loading,
        cart,
        visible,
        actions: {
          addItem,
          removeItem,
          updateQuantity,
          addDiscount,
          removeDiscount,
          createPaymentSession,
          setPaymentSession,
          completeCart,
          getCartShippingOptions,
          addShippingMethod,
          addShippingAddress,
          updateCart,
          setVisible,
        },
      }}
    />
  );
};
