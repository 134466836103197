// sort-imports-ignore
import Medusa from '@medusajs/medusa-js';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios from 'axios';
import { Settings } from 'luxon';
import React from 'react';

import { LayoutProvider } from './src/context/layout-context';
import { MedusaProvider } from './src/context/medusa-context';

import './src/styles/theme.css';
import './src/styles/App.less';

const BASE_URL =
  process.env.GATSBY_MEDUSA_BACKEND_URL || 'http://localhost:9000';

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnMount: false } },
});

const medusaClient = new Medusa({
  maxRetries: 2,
  baseUrl: BASE_URL,
});

Settings.defaultLocale = 'th';

export const wrapPageElement = ({ element, props }) => {
  return (
    <>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Noto+Sans+Thai:300,400,500,600,700|Inter:300,400,500,600,700"
      ></link>
      <QueryClientProvider client={queryClient}>
        <MedusaProvider client={medusaClient}>
          <LayoutProvider>{element}</LayoutProvider>
        </MedusaProvider>
      </QueryClientProvider>
    </>
  );
};
